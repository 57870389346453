"use client";
import { metadata } from "@app/_metadata";
import { RoboLion } from "@chat-ai/_components/robo-lion";
import { TopBar } from "@shared-components/global/top-bar";
import { ToolCard } from "@shared-components/tool-card";
import { api } from "@shared-server/api";
import { Info } from "lucide-react";

export default function DashboardPage() {
  // Feature flags for user
  const activeFeatureFlags = api.feature.allEnabledForMe.useQuery();

  return (
    <>
      <TopBar aiTools={[]} />
      <div className="flex justify-center md:py-16">
        <div className="gap-16 p-4 md:flex md:flex-row lg:max-w-[80%] xl:max-w-[60%]">
          <span className="relative mb-6 inline-block md:hidden">
            <h1 className="mt-4 text-3xl font-bold">{"LEO AI"}</h1>
            <RoboLion className="absolute -right-5 -top-1 w-5 fill-leo-light-green-600" />
          </span>
          <div className="order-first md:order-last md:mt-20 md:w-2/3">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              {metadata.aiTools
                .filter((appConfig) => appConfig.tool)
                .filter(
                  (appConfig) =>
                    !appConfig.requiredFeatureFlags ||
                    appConfig.requiredFeatureFlags.every((flag) =>
                      activeFeatureFlags.data?.includes(flag),
                    ),
                )
                .map((appConfig) => (
                  <ToolCard key={appConfig.slug} {...appConfig} />
                ))}
            </div>
            <div className="mt-8 text-gray-400">
              <Info size={16} strokeWidth={1.5} className="-mt-1 mr-1 inline" />
              {"To make the most of LEO AI, visit our page on "}
              <a
                className="text-link"
                href="https://leopharma.sharepoint.com/teams/LEO-PHARMA-ACADEMY/SitePages/LEO-OpenAI.aspx"
              >
                {"LEO Academy"}
              </a>
              {"."}
            </div>
          </div>

          <div className="md:w-1/3">
            <span className="relative mb-6 hidden md:visible md:inline-block">
              <h1 className="mt-4 text-5xl font-bold">{"LEO AI"}</h1>
              <RoboLion className="absolute -right-6 -top-2 w-6 fill-leo-light-green-600" />
            </span>
            <h1 className="mt-4 text-xl font-bold md:hidden">
              {"About LEO AI"}
            </h1>
            <p>{`Welcome to LEO AI, your platform for AI-powered tools to help you work smarter. By leveraging advanced machine learning algorithms, LEO AI is the smart assistant for a wide range of tasks, from drafting and summarising content, ideation, translations, and image generation.`}</p>
            <p className="mt-2">{`To start your journey, choose the tool that fits your request and start exploring!`}</p>
            <h2 className="display-none mb-4 mt-8 opacity-60">{"Updates"}</h2>
            {metadata.updates.map((update) => {
              return (
                <a
                  href={update.url + "#" + update.key}
                  key={update.url + "#" + update.key}
                  className="group mb-4 block"
                >
                  <p className="mt-0 text-xs text-leo-light-green-600">
                    {update.date}
                  </p>
                  <h3 className="font-semibold group-hover:underline">
                    {update.title}
                  </h3>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
