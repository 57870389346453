import { PopoverClose } from "@radix-ui/react-popover";
import { useAppFromPath } from "@shared-utils/hooks/use-app-from-path";
import { languages } from "@shared-utils/localization/languages";
import { Globe } from "lucide-react";
import { ListItem } from "./list-item";
import { useLocalization } from "./localization-provider";
import { Button } from "./shadcn/button";
import { Label } from "./shadcn/label";
import { Popover, PopoverContent, PopoverTrigger } from "./shadcn/popover";

export const LanguageDropdown = () => {
  const { setLanguage, language } = useLocalization();

  // Get languages
  const app = useAppFromPath();
  const appLanguages = languages[app];
  if (!appLanguages || appLanguages.length === 0) {
    return null;
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          size="sm"
          className="flex flex-row items-center gap-1 pl-2"
        >
          <Globe size={18} strokeWidth={1.5} />
          <span className="relative top-0.5 block font-bold">
            {appLanguages?.find((l) => l.code === language)?.code}
          </span>
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" className="w-[150px] p-0">
        <Label className="block p-2">{"Pick language"}</Label>
        {appLanguages?.map((listLanguage) => (
          <PopoverClose key={listLanguage.code} asChild>
            <ListItem
              className="flex flex-row items-center gap-2"
              onClick={() => setLanguage(listLanguage.code)}
              selected={language === listLanguage.code}
            >
              <span className="font-bold">{`${listLanguage.code}`}</span>
              <span>{"|"}</span>
              <span>{listLanguage.name}</span>
            </ListItem>
          </PopoverClose>
        ))}
      </PopoverContent>
    </Popover>
  );
};
