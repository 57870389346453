"use client";

import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import { cn } from "@shared-utils/ui";

const listItemVariants = cva(
  "last:border-b border-border border-t text-left w-full font-semibold block items-center  text-xs transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "hover:bg-accent",
        sand: "hover:bg-leo-sand-100 bg-leo-sand-200",
      },
      size: {
        default: "h-8 px-2 py-2",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof listItemVariants> {
  asChild?: boolean;
  selected?: boolean;
}

const ListItem = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(
          listItemVariants({ variant, size, className }),
          {
            "!bg-select": props.selected,
          },
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
ListItem.displayName = "ListItem";

export { ListItem, listItemVariants };
