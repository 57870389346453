// Supported feature flags
export const FeatureFlag = [
  "ISEARCH__APP__188825",
  "TRAINING_AI__APP__189322",
] as const;
export type FeatureFlagType = (typeof FeatureFlag)[number];

// Map to print feature flags
export const featuresMap: { [key in FeatureFlagType]: string } = {
  ISEARCH__APP__188825: "iSearch",
  TRAINING_AI__APP__189322: "Training AI",
};
