import { getIconFromSlug, metadata, type AITool } from "@app/_metadata";
import { AboutContent } from "@shared-components/global/about-content";
import { DarkModeSwitch } from "@shared-components/global/dark-mode-switch";
import { Button } from "@shared-components/shadcn/button";
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "@shared-components/shadcn/dialog";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@shared-components/shadcn/dropdown-menu";
import { api } from "@shared-server/api";
import { compilePath } from "@shared-utils/routing";
import { cn } from "@shared-utils/ui";
import {
  BookOpenText,
  Bug,
  FileQuestion,
  GraduationCap,
  Menu,
  MessageCircle,
  Settings,
  AreaChart,
} from "lucide-react";
import Link from "next/link";
import { useState } from "react";

interface TopBarMenuProps {
  activeAiTool: AITool;
}

export const TopBarMenu = (props: TopBarMenuProps) => {
  const [showAbout, setShowAbout] = useState(false);

  // Check if user is admin
  const isAdminQuery = api.admin.isAdmin.useQuery();
  const isAdmin = !isAdminQuery.isLoading && isAdminQuery.data;

  // Feature flags for user
  const activeFeatureFlags = api.feature.allEnabledForMe.useQuery();

  // Fetch runtime envs to compile Azure Insights link
  const { data: runtimeEnvs } = api.utils.getSafeRuntimeEnvs.useQuery();
  let azureInsightsUrl = "";
  if (runtimeEnvs) {
    const env = runtimeEnvs.ENV === "local" ? "dev" : runtimeEnvs.ENV;
    azureInsightsUrl = `https://portal.azure.com/#@leopharma.onmicrosoft.com/resource/subscriptions/${runtimeEnvs.AZURE_AD_SUBSCRIPTION_ID}/resourceGroups/leo-rg-${runtimeEnvs.APP_NAME_BASE}-${env}-typescript-app/providers/Microsoft.Insights/components/leo-app-insights-${runtimeEnvs.APP_NAME_BASE}-${env}/failures`;
  }

  return (
    <>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger asChild>
          <Button size="sm" variant="ghost">
            <Menu size={22} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="w-56"
          side="bottom"
          align="end"
          sideOffset={15}
        >
          <DropdownMenuLabel className="text-sm font-bold">
            {"Tools"}
          </DropdownMenuLabel>
          {metadata.aiTools
            ?.filter((aiTool) => aiTool.tool)
            .filter(
              (appConfig) =>
                !appConfig.requiredFeatureFlags ||
                appConfig.requiredFeatureFlags.every((flag) =>
                  activeFeatureFlags.data?.includes(flag),
                ),
            )
            .map((aiTool) => (
              <DropdownMenuItem asChild key={aiTool.slug}>
                <Link href={compilePath(aiTool.slug)}>
                  <div
                    className={cn(
                      aiTool.meta.backgroundColor,
                      "flex h-6 w-6 items-center justify-center rounded-sm text-white",
                    )}
                  >
                    {getIconFromSlug(aiTool.slug, {
                      strokeWidth: 1.5,
                      size: 16,
                    })}
                  </div>
                  <span className="ml-2 text-lg font-semibold">
                    {aiTool.meta.name}
                  </span>
                </Link>
              </DropdownMenuItem>
            ))}
          <DropdownMenuSeparator />
          <DropdownMenuLabel className="text-sm font-bold">
            {"Learn and support"}
          </DropdownMenuLabel>
          <DropdownMenuItem asChild>
            <a
              target="_blank"
              href="https://leopharma.sharepoint.com/teams/LEO-PHARMA-ACADEMY/SitePages/LEO-OpenAI.aspx"
            >
              <GraduationCap className="mr-2" strokeWidth={1.5} size={16} />
              {"LEO Academy"}
            </a>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <a
              target="_blank"
              href="https://leopharma.sharepoint.com/teams/LEO-PHARMA-ACADEMY/Shared%20Documents/Digital%20Skills/LEO%20AI%20FAQ%2018Apr24.pdf"
            >
              <FileQuestion className="mr-2" strokeWidth={1.5} size={16} />
              {"FAQ"}
            </a>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <a href="mailto:data_solutions@leo-pharma.com?subject=LEO%20OpenAI%20%7C%20Feedback&body=We%20are%20very%20happy%20that%20you%20are%20using%20LEO%20OpenAI%2C%20and%20would%20love%20to%20hear%20your%20feedback%20or%20suggestions%20for%20improvements.%20">
              <MessageCircle className="mr-2" strokeWidth={1.5} size={16} />
              {"Feedback"}
            </a>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <a href="mailto:data_solutions@leo-pharma.com?subject=LEO%20OpenAI%20%7C%20Bug%20report&body=Sorry%20to%20hear%20that%20you%20are%20experiencing%20problems%20using%20LEO%20OpenAI.%20Please%20describe%20the%20issue%20here%20and%20if%20possible%2C%20please%20include%20steps%20to%20recreate%20the%20issue.%20%0AWe%20will%20do%20our%20best%20to%20help%20resolve%20the%20issue%20ASAP.%20">
              <Bug className="mr-2" strokeWidth={1.5} size={16} />
              {"Report bug"}
            </a>
          </DropdownMenuItem>
          {isAdmin && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuLabel className="text-sm font-bold">
                {"Admin"}
              </DropdownMenuLabel>
              <DropdownMenuItem asChild>
                <a href="/docs">
                  <BookOpenText className="mr-2" strokeWidth={1.5} size={16} />
                  {"Docs"}
                </a>
              </DropdownMenuItem>
              {azureInsightsUrl && (
                <DropdownMenuItem asChild>
                  <a href={azureInsightsUrl}>
                    <AreaChart className="mr-2" strokeWidth={1.5} size={16} />
                    {"Azure Insights"}
                  </a>
                </DropdownMenuItem>
              )}

              <DropdownMenuItem asChild>
                <a href="/admin/feature-flags">
                  <Settings className="mr-2" strokeWidth={1.5} size={16} />
                  {"Admin"}
                </a>
              </DropdownMenuItem>
            </>
          )}
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => setShowAbout(true)}>
            {"About"}
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DarkModeSwitch />
        </DropdownMenuContent>
      </DropdownMenu>
      <Dialog open={showAbout} onOpenChange={(open) => setShowAbout(open)}>
        <DialogContent className="p-0">
          <VisuallyHidden.Root>
            <DialogTitle>About</DialogTitle>
          </VisuallyHidden.Root>
          <AboutContent
            onClose={() => setShowAbout(false)}
            aiTool={props.activeAiTool}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
