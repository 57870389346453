"use client";
import { type AppEnumType } from "@shared-database/enums";
import { useLocalStorage } from "@shared-utils/hooks/use-local-storage";
import { translations } from "@shared-utils/localization/translations";
import _ from "lodash";
import React, { createContext, useContext, type ReactNode } from "react";

export type TranslateOptions = {
  app: AppEnumType;
  translationKey: string;
  value: string;
  description: string;
};

type LocalizationContextType = {
  translate: (options: TranslateOptions) => string;
  setLanguage: (lang: string) => void;
  language: string;
};

// Create context
const LocalizationContext = createContext<LocalizationContextType | undefined>(
  undefined,
);

// Provider props
type LocalizationProviderProps = {
  children: ReactNode;
};

// Provider component
export const LocalizationProvider: React.FC<LocalizationProviderProps> = ({
  children,
}) => {
  const [language, setLanguage] = useLocalStorage<string>("language-", "EN"); // Default language

  const translate = (options: TranslateOptions): string => {
    const translated = _.get(
      translations,
      [options.app, language, options.translationKey],
      options.value,
    );

    /* this is a temp solution to track translations and save them to local storage for easy export
    // TODO: Temp solution to track translations and save them to local storage for easy export
    // eslint-disable-next-line
    const history = JSON.parse(
      localStorage.getItem("translation-history-3") || "{}",
    );
    _.set(history, [options.app, language, options.translationKey], {
      value: options.value,
      autoTranslated: translated,
      description: options.description,
    });
    localStorage.setItem("translation-history", JSON.stringify(history));
    */

    // Return translation
    return translated;
  };
  return (
    <LocalizationContext.Provider value={{ translate, setLanguage, language }}>
      {children}
    </LocalizationContext.Provider>
  );
};

// Custom hook
export const useLocalization = () => {
  const context = useContext(LocalizationContext);
  if (!context) {
    throw new Error("useTranslation must be used within a TranslationProvider");
  }
  return context;
};
