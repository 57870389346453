// Necessary if using App Router to ensure this file runs on the client
"use client";

import { type RumActionEvent, datadogRum } from "@datadog/browser-rum";
import { useSession } from "next-auth/react";
import { useEffect } from "react";

type DatadogInitProps = {
  applicationId: string;
  clientToken: string;
  service: string;
  env: string;
};

export default function DatadogInit(props: DatadogInitProps) {
  const session = useSession();
  // Render nothing - this component is only included so that the init code
  // above will run client-side

  useEffect(
    () => {
      // Init
      datadogRum.init({
        ...props,
        beforeSend: (event) => {
          // pre-flight global masking of action elment names - done to make sure no unwanted info leaks to DD
          // https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm
          const actionEvent = event as RumActionEvent;
          if (
            actionEvent.action &&
            actionEvent.action.target &&
            actionEvent.action.type !== "custom"
          ) {
            try {
              // set new action target name
              actionEvent.action.target.name = actionEvent.action.target.name
                .split("") // split to single chararter string
                .map((c) => (c === " " ? " " : "x")) // override all non-space charaters with x
                .join(""); // rejoin name to single string
            } catch (error) {
              // if something goes wrong during masking return false to omit tracking of the event
              console.error(error);
              return false;
            }
          }
          return true;
        },
        site: "datadoghq.eu",
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: false,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask",
        // Specify URLs to propagate trace headers for connection between RUM and backend trace
        allowedTracingUrls: [
          {
            match: "https://example.com/api/",
            propagatorTypes: ["tracecontext"],
          },
        ],
      });
      // Set user context
      if (session.data?.user) {
        datadogRum.setUser({
          id: session.data?.user.email || undefined,
          name: session.data?.user.name || undefined,
          email: session.data?.user.email || undefined,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return null;
}
