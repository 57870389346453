import {
  type ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@shared-components/shadcn/chart";
import { api } from "@shared-server/api";
import { Bar, CartesianGrid, YAxis, XAxis, Legend, BarChart } from "recharts";

const chartConfig: ChartConfig = {
  count: {
    label: "Messages count per chat with files",
  },
};

interface MessagesCountPerChatWithFilesProps {
  startDate: Date;
  endDate: Date;
}

export const MessagesCountPerChatWithFiles = (
  props: MessagesCountPerChatWithFilesProps,
) => {
  // Query
  const coreMetricsQuery =
    api.coreMetrics.messagesCountPerChatWithFiles.useQuery({
      startDate: props.startDate,
      endDate: props.endDate,
    });

  const chartData = coreMetricsQuery.data ?? [];

  return (
    <div>
      <h2 className="mb-4">Messages count per chat with files</h2>
      <ChartContainer
        config={chartConfig}
        className="aspect-auto h-[150px] w-full"
      >
        <BarChart accessibilityLayer data={chartData}>
          <CartesianGrid vertical={false} />
          <XAxis xAxisId="groupCount" dataKey="groupCount" />
          <YAxis
            yAxisId="count"
            dataKey={"count"}
            interval={1}
            orientation="left"
          />
          <ChartTooltip
            content={
              <ChartTooltipContent
                className="w-[150px]"
                nameKey="date"
                labelFormatter={(value: string) => {
                  return value;
                }}
              />
            }
          />
          <Legend />
          <Bar
            yAxisId="count"
            xAxisId="groupCount"
            dataKey={"count"}
            className="fill-leo-sunflower-600"
            fill="#eaaa00"
          />
        </BarChart>
      </ChartContainer>
    </div>
  );
};
