import { type AppEnumType } from "@shared-database/enums";

export const languages: {
  [key in AppEnumType]?: {
    code: string;
    name: string;
  }[];
} = {
  TRAINING_AI: [
    {
      code: "EN",
      name: "English",
    },
    {
      code: "ZH",
      name: "Chinese",
    },
  ],
};
