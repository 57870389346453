import { SummaryTable } from "@shared-components/core-metrics/summary-table";
import { api } from "@shared-server/api";
import { useLocalStorage } from "@shared-utils/hooks/use-local-storage";
import { BarChart3, ChevronLeft, ChevronRight } from "lucide-react";
import { useEffect, useState } from "react";
import { Chats } from "./core-metrics/chats";
import { Images } from "./core-metrics/images";
import { Button } from "./shadcn/button";
import { Sheet, SheetContent, SheetTitle } from "./shadcn/sheet";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { MessagesCountPerChatWithFiles } from "@shared-components/core-metrics/messages-count-per-chat-with-files";
import { Tab, TabGroup } from "@shared-components/tab-group";
import { AiModelsCountInChats } from "@shared-components/core-metrics/ai-models-count-in-chats-per-dates";
export const CoreMetricsSheet = () => {
  const isAdminQuery = api.admin.isAdmin.useQuery();
  const isAdmin = !isAdminQuery.isLoading && isAdminQuery.data;

  // Local state
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // Date states
  const [range, setRange] = useLocalStorage<"all" | "year" | "week" | "month">(
    "core-metrics-range",
    "month",
  );
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [offset, setOffset] = useState<number>(0);

  // Update state on range change or offset change
  useEffect(() => {
    const date = new Date();

    switch (range) {
      case "all":
        setStartDate(new Date("2024-04-22"));
        setEndDate(new Date(date));
        break;
      case "year":
        date.setFullYear(date.getFullYear() + offset);
        setStartDate(new Date(date.getFullYear(), 0, 1));
        setEndDate(new Date(date.getFullYear() + 1, 0, 0));
        break;
      case "month":
        date.setMonth(date.getMonth() + offset);
        setStartDate(new Date(date.getFullYear(), date.getMonth(), 1));
        setEndDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
        break;
      case "week":
        date.setDate(date.getDate() + offset * 7);
        const day = date.getDay();
        const diff = date.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        setStartDate(new Date(date.setDate(diff)));
        setEndDate(new Date(date.setDate(diff + 6)));
        break;
    }
  }, [offset, range]);

  useEffect(() => {
    setOffset(0);
  }, [range]);

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      {isAdmin && (
        <Button onClick={() => setIsOpen(true)} variant="ghost">
          <BarChart3 />
        </Button>
      )}
      <Sheet open={isOpen} onOpenChange={(value) => setIsOpen(value)}>
        <SheetContent className="bg-leo-sand-200">
          <VisuallyHidden.Root>
            <SheetTitle>Core Metrics</SheetTitle>
          </VisuallyHidden.Root>
          <div className="mt-4 flex justify-between">
            <h1>{"Core Metrics"}</h1>
            <div className="flex items-center gap-2">
              <span>
                {`${startDate?.toLocaleDateString()}-${endDate?.toLocaleDateString()}`}
              </span>
              <Button
                variant={"secondary"}
                onClick={() => setOffset(offset - 1)}
              >
                <ChevronLeft />
              </Button>
              <Button
                variant={"secondary"}
                onClick={() => setOffset(offset + 1)}
              >
                <ChevronRight />
              </Button>
              <Button
                variant={range === "week" ? "default" : "secondary"}
                onClick={() => setRange("week")}
              >
                {"Week"}
              </Button>
              <Button
                onClick={() => setRange("month")}
                variant={range === "month" ? "default" : "secondary"}
              >
                {"Month"}
              </Button>
              <Button
                onClick={() => setRange("year")}
                variant={range === "year" ? "default" : "secondary"}
              >
                {"Year"}
              </Button>
              <Button
                onClick={() => setRange("all")}
                variant={range === "all" ? "default" : "secondary"}
              >
                {"All"}
              </Button>
            </div>
          </div>
          <div className="-ml-6 mt-4 flex w-full flex-col gap-6">
            {startDate && endDate && (
              <TabGroup name="admin-feature-flags-tabs">
                <Tab
                  key={0}
                  label={"Summary"}
                  className="-mr-12 pb-72 pl-6 pr-6 pt-4"
                >
                  <SummaryTable startDate={startDate} endDate={endDate} />
                  <Chats startDate={startDate} endDate={endDate} />
                  <Images startDate={startDate} endDate={endDate} />
                </Tab>
                <Tab
                  key={1}
                  label={"Chat"}
                  className="-mr-12 pb-72 pl-6 pr-6 pt-4"
                >
                  <MessagesCountPerChatWithFiles
                    startDate={startDate}
                    endDate={endDate}
                  />
                  <AiModelsCountInChats
                    startDate={startDate}
                    endDate={endDate}
                  />
                </Tab>
              </TabGroup>
            )}
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
};
