import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "@shared-components/shadcn/tabs";
import { useLocalStorage } from "@shared-utils/hooks/use-local-storage";
import { cn } from "@shared-utils/ui";
import { type ReactElement, type ReactNode } from "react";

interface TabGroupProps {
  children: ReactElement<TabProps>[] | ReactElement<TabProps>;
  name: string;
  className?: string;
  contentClassName?: string;
  triggerClassName?: string;
  topPanelClassName?: string;
}

export const TabGroup = (props: TabGroupProps) => {
  const children = [props.children].flat();

  const [tabKey, setTabKey] = useLocalStorage(
    props.name,
    children[0]?.props.label,
  );

  return (
    <Tabs
      value={tabKey}
      onValueChange={setTabKey}
      className={cn("flex flex-1 flex-col", props.className)}
    >
      <TabsList className={cn(props.topPanelClassName)}>
        {children.map((child, index) => (
          <TabsTrigger
            key={index}
            value={child.props.label}
            className={cn(props.triggerClassName)}
          >
            {child.props.label}
          </TabsTrigger>
        ))}
      </TabsList>
      {children.map((child, index) => (
        <TabsContent
          key={index}
          className={cn(props.contentClassName, child.props.className)}
          value={child.props.label}
        >
          {child}
        </TabsContent>
      ))}
    </Tabs>
  );
};

interface TabProps {
  label: string;
  className?: string;
  children: ReactNode;
}

export const Tab = ({ children }: TabProps) => {
  return <>{children}</>;
};
